import React, { type ReactNode, useCallback, useMemo, useState } from 'react';

import { loadEntryPoint } from 'react-relay';
import { mergeRefs } from 'use-callback-ref';

import { Popup, type PopupProps, type TriggerProps } from '@atlaskit/popup';
import Spinner from '@atlaskit/spinner';
import type {
	AnyEntryPoint,
	EntryPointRef,
	ParamsOfEntryPoint,
	RuntimePropsOfEntryPoint,
} from '@atlassian/entry-point-types';
import { usePressablePreloadRef } from '@atlassian/entry-point-use-trigger';
import {
	type ErrorCallback,
	type ErrorFallback,
	InternalEntryPointContainer,
} from '@atlassian/internal-entry-point-container';
import type { JSResourceReference } from '@atlassian/react-async';
import { useRelayEnvironmentProvider } from '@atlassian/relay-environment-provider';

import { useClickToCloseRef } from './useClickToCloseRef';

const emptyEntryPointParams = {} as ParamsOfEntryPoint<AnyEntryPoint>;
const emptyEntryPointProps = {} as RuntimePropsOfEntryPoint<AnyEntryPoint>;

type UnionOmit<T, K extends string | number | symbol> = T extends unknown ? Omit<T, K> : never;

type CustomPopupProps = UnionOmit<PopupProps, 'isOpen' | 'content'>;

export type Props<TEntryPoint> = {
	trigger: (triggerProps: TriggerProps) => ReactNode;
	entryPoint: TEntryPoint;
	entryPointParams?: ParamsOfEntryPoint<TEntryPoint>;
	entryPointProps?: RuntimePropsOfEntryPoint<TEntryPoint>;
	fallback?: ReactNode;
	errorFallback?: ErrorFallback;
	onError?: ErrorCallback;
} & CustomPopupProps;

export function PopupTrigger<TEntryPoint extends AnyEntryPoint>({
	trigger,
	entryPoint,
	entryPointParams,
	entryPointProps,
	fallback,
	errorFallback,
	onError,
	onClose,
	...popupProps
}: Props<TEntryPoint>) {
	const environmentProvider = useRelayEnvironmentProvider();

	const id = useMemo((): string => {
		return (entryPoint.root as JSResourceReference<any>).getModuleName() || 'unknown';
	}, [entryPoint]);

	const [isOpen, setIsOpen] = useState(false);
	const [content, setContent] = useState<ReactNode | null>(null);

	const handleOpen = useCallback(() => {
		setIsOpen(true);
	}, []);

	const handleClose = useCallback(
		(
			event: Event | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | null,
			currentLevel?: any,
		) => {
			setIsOpen(false);
			onClose?.(event, currentLevel);
		},
		[onClose],
	);

	const triggerCloseRef = useClickToCloseRef({
		isOpen,
		handleClose,
	});

	const load = useCallback(
		() =>
			loadEntryPoint<TEntryPoint>(
				environmentProvider,
				entryPoint,
				entryPointParams ?? emptyEntryPointParams,
			),
		[entryPoint, entryPointParams, environmentProvider],
	);

	const onLoad = useCallback(
		({ reference: entryPointReference }: { reference: EntryPointRef<TEntryPoint> }) => {
			// avoid re-loading entry-point when closing popup
			if (isOpen) {
				return;
			}

			handleOpen();

			setContent(
				<InternalEntryPointContainer
					id={id}
					entryPointReference={entryPointReference}
					fallback={fallback || <Spinner />}
					errorFallback={errorFallback}
					runtimeProps={entryPointProps || emptyEntryPointProps}
					onError={onError}
				/>,
			);
		},
		[setContent, id, isOpen, entryPointProps, fallback, errorFallback, handleOpen, onError],
	);

	const triggerRef = usePressablePreloadRef<EntryPointRef<TEntryPoint>>({
		load,
		onLoad,
	});

	const popupTrigger = useCallback(
		(triggerProps: TriggerProps) => {
			const mergedRef = mergeRefs([triggerRef, triggerProps.ref, triggerCloseRef]);
			return trigger({ ...triggerProps, ref: mergedRef });
		},
		[trigger, triggerRef, triggerCloseRef],
	);

	const popupContent = useCallback(() => {
		return content;
	}, [content]);

	return (
		<Popup
			{...popupProps}
			isOpen={isOpen}
			onClose={handleClose}
			trigger={popupTrigger}
			content={popupContent}
		/>
	);
}
