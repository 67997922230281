import { useCallback, useEffect, useMemo, useRef } from 'react';

import { FabricChannel } from '@atlaskit/analytics-listeners';
import {
	type AnalyticsEventPayload,
	UIAnalyticsEvent,
	useAnalyticsEvents,
} from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useEditionAwareness } from '../services/edition-awareness-context';
import type { ExperimentCohortControlVariation, ExperimentsMeta } from '../types';

export const ANALYTICS_CHANNEL = FabricChannel.growth;

export const getAttributesFromContexts = (context: unknown): Record<string, any> => {
	if (!context || !Array.isArray(context)) {
		return {};
	}

	return context.reduce((acc, x) => {
		if (x.attributes) {
			return {
				...acc,
				...x.attributes,
			};
		}

		return {
			...acc,
			...(x ?? {}),
		};
	}, {});
};

export const defaultAttributes = {
	packageName: '@atlassian/edition-awareness',
	source: 'editionAwareness',
};

export function useAnalytics() {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const editionAwarenessContext = useEditionAwareness();

	const experimentMeta: ExperimentsMeta = useMemo(() => {
		// I wanted to reference the utility function but,
		// that file imports AtlBrowserStorageLocal which makes the VR tests to fail with timeout, even if is not executed on runtime
		//if (isFreeEduOrOrgSite(editionAwarenessContext)) {

		//Only add the metadata when the site may be eligible for the experiment
		if (
			editionAwarenessContext.edition === 'free' &&
			(editionAwarenessContext.siteType === 'EDU' || editionAwarenessContext.siteType === 'ORG')
		) {
			return [
				{
					key: 'offer_edu_org_discount',
					cohort: FeatureGates.getExperimentValue<ExperimentCohortControlVariation>(
						'offer_edu_org_discount',
						'cohort',
						'not-enrolled',
					),
					siteType: editionAwarenessContext.siteType,
				},
			];
		}
		return [];
	}, [editionAwarenessContext]);

	const sendAnalyticsEvent = useCallback(
		(payload: AnalyticsEventPayload | UIAnalyticsEvent) => {
			const editionAwarenessContextAttributes = {
				cloudId: editionAwarenessContext.cloudId,
				product: editionAwarenessContext.product,
				currentProductEdition: editionAwarenessContext.edition,
				isInvoiceable: editionAwarenessContext.isInvoiceable,
				canAddPaymentDetails: editionAwarenessContext.canAddPaymentDetails,
				trialDaysLeft: editionAwarenessContext.trialDaysLeft,
				trialLengthDays: editionAwarenessContext.trialLengthDays,
				isInTrial: editionAwarenessContext.isInTrial,
				isInPredunning: editionAwarenessContext.isInPredunning,
				isInReverseTrial: editionAwarenessContext.isInReverseTrial,
				canUpgrade: editionAwarenessContext.canUpgrade,
				config: editionAwarenessContext.config,
				userRoles: editionAwarenessContext.userRoles,
				paidEntitlement: editionAwarenessContext.paidEntitlement,
				billingSourceSystem: editionAwarenessContext.billingSourceSystem,
				experimentMeta,
			};

			if (payload instanceof UIAnalyticsEvent) {
				const contextAttributes = getAttributesFromContexts(payload.context);

				payload
					.update({
						...payload.payload,
						attributes: {
							...contextAttributes,
							...defaultAttributes,
							...payload.payload.attributes,
							...editionAwarenessContextAttributes,
						},
					})
					.fire(ANALYTICS_CHANNEL);
			} else {
				const event = createAnalyticsEvent({
					...payload,
					attributes: {
						...defaultAttributes,
						...payload.attributes,
						...editionAwarenessContextAttributes,
					},
				});

				const contextAttributes = getAttributesFromContexts(event.context);

				event
					.update({
						attributes: {
							...contextAttributes,
							...event.payload.attributes,
						},
					})
					.fire(ANALYTICS_CHANNEL);
			}
		},
		[createAnalyticsEvent, editionAwarenessContext, experimentMeta],
	);

	return {
		sendAnalyticsEvent,
	};
}

/**
 * Put at the end of your render tree, will fire a screen event once when first rendered with the `name`
 */
export function ScreenEvent({
	name,
	attributes,
}: {
	name: string;
	attributes?: Record<string, unknown>;
}) {
	const { sendAnalyticsEvent } = useAnalytics();
	const hasSent = useRef(false);

	useEffect(() => {
		if (hasSent.current) {
			return;
		}
		sendAnalyticsEvent({
			eventType: 'screen',
			name,
			attributes,
		});
		hasSent.current = true;
	}, [sendAnalyticsEvent, name, attributes]);

	return null;
}
