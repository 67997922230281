import { EditionAwarenessContextType } from './edition-awareness-context';

// User is eligible for TC experiment if the they are not in grace period, and are on CCP
export function isEligibleForTrialCountdown({
	isInTrial,
	isInPredunning,
	trialDaysLeft,
	trialLengthDays,
	billingSourceSystem,
}: EditionAwarenessContextType): boolean {
	return Boolean(
		isInTrial &&
			!isInPredunning &&
			trialDaysLeft &&
			trialLengthDays &&
			billingSourceSystem === 'CCP',
	);
}
