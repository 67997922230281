/**
 * @generated SignedSource<<5e6fb4a280fa8cf74b2d4b04f2b4461a>>
 * @relayHash e60169aeb87ec589197cbf2cbcfde966
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 59206be24b3f47af0c5811fd637470ae87fcb95edd5d5edcca4461c653cebf44

import type { ConcreteRequest, Query } from 'relay-runtime';
export type GrowthUnifiedProfileProductEdition = "ENTERPRISE" | "FREE" | "PREMIUM" | "STANDARD" | "%future added value";
export type JiraApplicationKey = "JIRA_CORE" | "JIRA_PRODUCT_DISCOVERY" | "JIRA_SERVICE_DESK" | "JIRA_SOFTWARE" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type dialogFreeToStandardEditionAwarenessQuery$variables = {
  chargeElement: string;
  cloudId: string;
  hamsProductKey: string;
  isConfluence: boolean;
  isJira: boolean;
  jiraApplicationKey: JiraApplicationKey;
  projectType: JiraProjectType;
  shouldIncludeGUPS: boolean;
};
export type dialogFreeToStandardEditionAwarenessQuery$data = {
  readonly confluence_searchUser?: {
    readonly nodes: ReadonlyArray<{
      readonly confluencePerson: {
        readonly accountId: string | null | undefined;
        readonly displayName: string | null | undefined;
        readonly profilePicture: {
          readonly path: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly confluence_storage?: {
    readonly bytesLimit: AGG$Long | null | undefined;
    readonly bytesUsed: AGG$Long;
    readonly isUnlimited: boolean | null | undefined;
  } | null | undefined;
  readonly growthUnifiedProfile_getUnifiedProfile?: {
    readonly sites: ReadonlyArray<{
      readonly products: ReadonlyArray<{
        readonly nbeRecommendation: {
          readonly edition: GrowthUnifiedProfileProductEdition | null | undefined;
          readonly recommendationDate: string | null | undefined;
        } | null | undefined;
        readonly productName: string | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly jira?: {
    readonly allJiraProjects: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly assignableUsers: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly accountId: string;
                readonly name: string;
                readonly picture: AGG$URL;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly attachmentStorageAllowed: AGG$Long | null | undefined;
    readonly attachmentStorageIsUnlimited: boolean | null | undefined;
    readonly attachmentStorageUsed: AGG$Long | null | undefined;
  } | null | undefined;
  readonly tenantContexts: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly experienceCapabilities: {
          readonly changeOfferingV2: {
            readonly experienceUrl: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly latestUsageForChargeElement: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type dialogFreeToStandardEditionAwarenessQuery = {
  response: dialogFreeToStandardEditionAwarenessQuery$data;
  variables: dialogFreeToStandardEditionAwarenessQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chargeElement"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hamsProductKey"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isConfluence"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isJira"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jiraApplicationKey"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectType"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldIncludeGUPS"
},
v8 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "cloudIds.0",
        "variableName": "cloudId"
      }
    ],
    "kind": "ListValue",
    "name": "cloudIds"
  }
],
v9 = [
  {
    "kind": "Variable",
    "name": "hamsProductKey",
    "variableName": "hamsProductKey"
  }
],
v10 = [
  {
    "kind": "Literal",
    "name": "offeringName",
    "value": "Standard"
  }
],
v11 = {
  "kind": "ScalarField",
  "name": "experienceUrl"
},
v12 = {
  "args": [
    {
      "kind": "Variable",
      "name": "chargeElement",
      "variableName": "chargeElement"
    }
  ],
  "kind": "ScalarField",
  "name": "latestUsageForChargeElement"
},
v13 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v14 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v15 = {
  "condition": "isConfluence",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": [
        (v13/*: any*/),
        {
          "kind": "Literal",
          "name": "cql",
          "value": "type=user"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        }
      ],
      "concreteType": "ConfluenceSearchConnection",
      "kind": "LinkedField",
      "name": "confluence_searchUser",
      "plural": false,
      "selections": [
        {
          "concreteType": "ConfluenceSearchResponse",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "concreteType": "ConfluencePerson",
              "kind": "LinkedField",
              "name": "confluencePerson",
              "plural": false,
              "selections": [
                (v14/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "displayName"
                },
                {
                  "concreteType": "Icon",
                  "kind": "LinkedField",
                  "name": "profilePicture",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "path"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "concreteType": "ConfluenceStorage",
      "kind": "LinkedField",
      "name": "confluence_storage",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isUnlimited"
        },
        {
          "kind": "ScalarField",
          "name": "bytesUsed"
        },
        {
          "kind": "ScalarField",
          "name": "bytesLimit"
        }
      ]
    }
  ]
},
v16 = {
  "condition": "shouldIncludeGUPS",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "tenantId",
          "variableName": "cloudId"
        }
      ],
      "concreteType": "GrowthUnifiedProfileResult",
      "kind": "LinkedField",
      "name": "growthUnifiedProfile_getUnifiedProfile",
      "plural": false,
      "selections": [
        {
          "concreteType": "GrowthUnifiedProfileSiteDetails",
          "kind": "LinkedField",
          "name": "sites",
          "plural": true,
          "selections": [
            {
              "concreteType": "GrowthUnifiedProfileProductDetails",
              "kind": "LinkedField",
              "name": "products",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "productName"
                },
                {
                  "concreteType": "GrowthUnifiedProfileProductNBE",
                  "kind": "LinkedField",
                  "name": "nbeRecommendation",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "edition"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "recommendationDate"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v17 = [
  {
    "kind": "Variable",
    "name": "applicationKey",
    "variableName": "jiraApplicationKey"
  },
  (v13/*: any*/)
],
v18 = {
  "args": (v17/*: any*/),
  "kind": "ScalarField",
  "name": "attachmentStorageAllowed"
},
v19 = {
  "args": (v17/*: any*/),
  "kind": "ScalarField",
  "name": "attachmentStorageUsed"
},
v20 = {
  "args": (v17/*: any*/),
  "kind": "ScalarField",
  "name": "attachmentStorageIsUnlimited"
},
v21 = [
  (v13/*: any*/),
  {
    "fields": [
      {
        "items": [
          {
            "kind": "Variable",
            "name": "types.0",
            "variableName": "projectType"
          }
        ],
        "kind": "ListValue",
        "name": "types"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v22 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v23 = {
  "kind": "ScalarField",
  "name": "picture"
},
v24 = {
  "kind": "ScalarField",
  "name": "name"
},
v25 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v26 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "name": "dialogFreeToStandardEditionAwarenessQuery",
    "selections": [
      {
        "args": (v8/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v9/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "experienceCapabilities",
                    "plural": false,
                    "selections": [
                      {
                        "args": (v10/*: any*/),
                        "kind": "LinkedField",
                        "name": "changeOfferingV2",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/)
                        ],
                        "storageKey": "changeOfferingV2(offeringName:\"Standard\")"
                      }
                    ]
                  },
                  (v12/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      (v15/*: any*/),
      (v16/*: any*/),
      {
        "condition": "isJira",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "args": (v21/*: any*/),
                "concreteType": "JiraProjectConnection",
                "kind": "LinkedField",
                "name": "allJiraProjects",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": (v22/*: any*/),
                            "concreteType": "JiraAssignableUsersConnection",
                            "kind": "LinkedField",
                            "name": "assignableUsers",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraAssignableUsersEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v14/*: any*/),
                                      (v23/*: any*/),
                                      (v24/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "assignableUsers(first:10)"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "dialogFreeToStandardEditionAwarenessQuery",
    "selections": [
      {
        "args": (v8/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v9/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              (v25/*: any*/),
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v25/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "experienceCapabilities",
                    "plural": false,
                    "selections": [
                      (v25/*: any*/),
                      {
                        "args": (v10/*: any*/),
                        "kind": "LinkedField",
                        "name": "changeOfferingV2",
                        "plural": false,
                        "selections": [
                          (v25/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": "changeOfferingV2(offeringName:\"Standard\")"
                      }
                    ]
                  },
                  (v12/*: any*/),
                  (v26/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      (v15/*: any*/),
      (v16/*: any*/),
      {
        "condition": "isJira",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "args": (v21/*: any*/),
                "concreteType": "JiraProjectConnection",
                "kind": "LinkedField",
                "name": "allJiraProjects",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": (v22/*: any*/),
                            "concreteType": "JiraAssignableUsersConnection",
                            "kind": "LinkedField",
                            "name": "assignableUsers",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraAssignableUsersEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v25/*: any*/),
                                      (v14/*: any*/),
                                      (v23/*: any*/),
                                      (v24/*: any*/),
                                      (v26/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "assignableUsers(first:10)"
                          },
                          (v26/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "59206be24b3f47af0c5811fd637470ae87fcb95edd5d5edcca4461c653cebf44",
    "metadata": {},
    "name": "dialogFreeToStandardEditionAwarenessQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "102da6eb251f8623305faa3a3de02b3a";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
