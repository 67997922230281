/**
 * @generated SignedSource<<bc54154f7066acc7214e25aceb496602>>
 * @relayHash 1423a04874ed975207143dab5f51ecb8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2624659d3ed05dc7f6603aed037fc52cc721780fc1cde3e766c7cb4f5df25557

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CcpEntitlementPreDunningStatus = "IN_PRE_DUNNING" | "NOT_IN_PRE_DUNNING" | "%future added value";
export type CommerceEntitlementFilter = {
  AND?: ReadonlyArray<CommerceEntitlementFilter | null | undefined> | null | undefined;
  OR?: ReadonlyArray<CommerceEntitlementFilter | null | undefined> | null | undefined;
  inPreDunning?: boolean | null | undefined;
  inTrialOrPreDunning?: boolean | null | undefined;
};
export type editionAwarenessQuery$variables = {
  chargeElement: string;
  cloudId: string;
  entitlementFilter?: CommerceEntitlementFilter | null | undefined;
  productKey: string;
  shouldCheckBillingAdmin: boolean;
  shouldCheckForChangeOffering: boolean;
  shouldCheckForChargeQuantities: boolean;
  shouldCheckForPaidOffering: boolean;
  shouldCheckForTrial: boolean;
  shouldSkip: boolean;
  trialOfferingName: string;
};
export type editionAwarenessQuery$data = {
  readonly tenantContexts?: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly __typename: string;
        readonly experienceCapabilities?: {
          readonly changeOfferingV2: {
            readonly experienceUrl: string | null | undefined;
            readonly isAvailableToUser: boolean | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly offering?: {
          readonly trial: {
            readonly lengthDays: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly preDunning?: {
          readonly status: CcpEntitlementPreDunningStatus | null | undefined;
        } | null | undefined;
        readonly subscription?: {
          readonly accountDetails: {
            readonly invoiceGroup: {
              readonly experienceCapabilities: {
                readonly configurePaymentV2: {
                  readonly isAvailableToUser: boolean | null | undefined;
                } | null | undefined;
              } | null | undefined;
              readonly invoiceable: boolean | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly trial: {
            readonly timeLeft: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly transactionAccount?: {
          readonly isCurrentUserBillingAdmin: boolean | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly paidEntitlement?: {
        readonly __typename: string;
        readonly experienceCapabilities: {
          readonly changeOfferingV2: {
            readonly experienceUrl: string | null | undefined;
            readonly isAvailableToUser: boolean | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly subscription: {
          readonly accountDetails: {
            readonly invoiceGroup: {
              readonly invoiceable: boolean | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"audienceAndTiming_AtlassianEditionAwareness">;
};
export type editionAwarenessQuery = {
  response: editionAwarenessQuery$data;
  variables: editionAwarenessQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chargeElement"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entitlementFilter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productKey"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldCheckBillingAdmin"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldCheckForChangeOffering"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldCheckForChargeQuantities"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldCheckForPaidOffering"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldCheckForTrial"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldSkip"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "trialOfferingName"
},
v11 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "cloudIds.0",
        "variableName": "cloudId"
      }
    ],
    "kind": "ListValue",
    "name": "cloudIds"
  }
],
v12 = [
  {
    "kind": "Variable",
    "name": "hamsProductKey",
    "variableName": "productKey"
  }
],
v13 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "inTrialOrPreDunning": false
    }
  }
],
v14 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v15 = [
  {
    "kind": "Variable",
    "name": "offeringName",
    "variableName": "trialOfferingName"
  }
],
v16 = {
  "kind": "ScalarField",
  "name": "isAvailableToUser"
},
v17 = {
  "kind": "ScalarField",
  "name": "experienceUrl"
},
v18 = {
  "kind": "LinkedField",
  "name": "experienceCapabilities",
  "plural": false,
  "selections": [
    {
      "args": (v15/*: any*/),
      "kind": "LinkedField",
      "name": "changeOfferingV2",
      "plural": false,
      "selections": [
        (v16/*: any*/),
        (v17/*: any*/)
      ]
    }
  ]
},
v19 = {
  "kind": "ScalarField",
  "name": "invoiceable"
},
v20 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "entitlementFilter"
  }
],
v21 = {
  "kind": "ScalarField",
  "name": "status"
},
v22 = {
  "kind": "ScalarField",
  "name": "timeLeft"
},
v23 = {
  "kind": "ScalarField",
  "name": "lengthDays"
},
v24 = {
  "kind": "ScalarField",
  "name": "isCurrentUserBillingAdmin"
},
v25 = {
  "kind": "ScalarField",
  "name": "id"
},
v26 = {
  "kind": "LinkedField",
  "name": "experienceCapabilities",
  "plural": false,
  "selections": [
    (v14/*: any*/),
    {
      "args": (v15/*: any*/),
      "kind": "LinkedField",
      "name": "changeOfferingV2",
      "plural": false,
      "selections": [
        (v14/*: any*/),
        (v16/*: any*/),
        (v17/*: any*/)
      ]
    }
  ]
},
v27 = [
  (v25/*: any*/)
],
v28 = {
  "kind": "InlineFragment",
  "selections": (v27/*: any*/),
  "type": "CcpInvoiceGroup"
},
v29 = {
  "kind": "InlineFragment",
  "selections": (v27/*: any*/),
  "type": "CcpSubscription"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "name": "editionAwarenessQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "audienceAndTiming_AtlassianEditionAwareness"
      },
      {
        "condition": "shouldSkip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "args": (v11/*: any*/),
            "concreteType": "TenantContext",
            "kind": "LinkedField",
            "name": "tenantContexts",
            "plural": true,
            "selections": [
              {
                "args": (v12/*: any*/),
                "kind": "LinkedField",
                "name": "entitlementInfo",
                "plural": false,
                "selections": [
                  {
                    "condition": "shouldCheckForPaidOffering",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": "paidEntitlement",
                        "args": (v13/*: any*/),
                        "kind": "LinkedField",
                        "name": "entitlement",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v18/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "subscription",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "accountDetails",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "invoiceGroup",
                                    "plural": false,
                                    "selections": [
                                      (v19/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "storageKey": "entitlement(where:{\"inTrialOrPreDunning\":false})"
                      }
                    ]
                  },
                  {
                    "args": (v20/*: any*/),
                    "kind": "LinkedField",
                    "name": "entitlement",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "condition": "shouldCheckForChangeOffering",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          (v18/*: any*/)
                        ]
                      },
                      {
                        "condition": "shouldCheckForTrial",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "preDunning",
                            "plural": false,
                            "selections": [
                              (v21/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "name": "subscription",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "trial",
                                "plural": false,
                                "selections": [
                                  (v22/*: any*/)
                                ]
                              },
                              {
                                "kind": "LinkedField",
                                "name": "accountDetails",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "invoiceGroup",
                                    "plural": false,
                                    "selections": [
                                      (v19/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "name": "experienceCapabilities",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "configurePaymentV2",
                                            "plural": false,
                                            "selections": [
                                              (v16/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "name": "offering",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "trial",
                                "plural": false,
                                "selections": [
                                  (v23/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "shouldCheckBillingAdmin",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "transactionAccount",
                            "plural": false,
                            "selections": [
                              (v24/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v10/*: any*/),
      (v5/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "editionAwarenessQuery",
    "selections": [
      {
        "condition": "shouldSkip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "args": (v11/*: any*/),
            "concreteType": "TenantContext",
            "kind": "LinkedField",
            "name": "tenantContexts",
            "plural": true,
            "selections": [
              {
                "args": (v12/*: any*/),
                "kind": "LinkedField",
                "name": "entitlementInfo",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  {
                    "args": (v20/*: any*/),
                    "kind": "LinkedField",
                    "name": "entitlement",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "condition": "shouldCheckForChargeQuantities",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Variable",
                                "name": "chargeElement",
                                "variableName": "chargeElement"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "latestUsageForChargeElement"
                          }
                        ]
                      },
                      (v25/*: any*/),
                      {
                        "condition": "shouldCheckForChangeOffering",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          (v26/*: any*/)
                        ]
                      },
                      {
                        "condition": "shouldCheckForTrial",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "preDunning",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v21/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "name": "subscription",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "trial",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v22/*: any*/)
                                ]
                              },
                              {
                                "kind": "LinkedField",
                                "name": "accountDetails",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "name": "invoiceGroup",
                                    "plural": false,
                                    "selections": [
                                      (v14/*: any*/),
                                      (v19/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "name": "experienceCapabilities",
                                        "plural": false,
                                        "selections": [
                                          (v14/*: any*/),
                                          {
                                            "kind": "LinkedField",
                                            "name": "configurePaymentV2",
                                            "plural": false,
                                            "selections": [
                                              (v14/*: any*/),
                                              (v16/*: any*/)
                                            ]
                                          }
                                        ]
                                      },
                                      (v28/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              (v29/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "name": "offering",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "trial",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v23/*: any*/)
                                ]
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v27/*: any*/),
                                "type": "Node",
                                "abstractKey": "__isNode"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "shouldCheckBillingAdmin",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "transactionAccount",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v24/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": (v27/*: any*/),
                                "type": "CcpTransactionAccount"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "condition": "shouldCheckForPaidOffering",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": "paidEntitlement",
                        "args": (v13/*: any*/),
                        "kind": "LinkedField",
                        "name": "entitlement",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v26/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "subscription",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "accountDetails",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "name": "invoiceGroup",
                                    "plural": false,
                                    "selections": [
                                      (v14/*: any*/),
                                      (v19/*: any*/),
                                      (v28/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              (v29/*: any*/)
                            ]
                          },
                          (v25/*: any*/)
                        ],
                        "storageKey": "entitlement(where:{\"inTrialOrPreDunning\":false})"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2624659d3ed05dc7f6603aed037fc52cc721780fc1cde3e766c7cb4f5df25557",
    "metadata": {},
    "name": "editionAwarenessQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d0c24b21d0ccea9e535a3f7fbb9870e6";

export default node;
