/**
 * @generated SignedSource<<a6105e54f5c0236d797c6787daedc6c2>>
 * @relayHash ad253201e4efe5ead4789e6886333283
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c517efe1f278e6fcd26f8435f985e0afa9794c5e1e81615d9b040697b75959d3

import type { ConcreteRequest, Query } from 'relay-runtime';
export type dialogReverseTrialPopupEditionAwarenessQuery$variables = {
  cloudId: string;
  hamsProductKey: string;
};
export type dialogReverseTrialPopupEditionAwarenessQuery$data = {
  readonly tenantContexts: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly experienceCapabilities: {
          readonly changeOfferingV2: {
            readonly experienceUrl: string | null | undefined;
            readonly isAvailableToUser: boolean | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly subscription: {
          readonly accountDetails: {
            readonly invoiceGroup: {
              readonly experienceCapabilities: {
                readonly configurePaymentV2: {
                  readonly experienceUrl: string | null | undefined;
                  readonly isAvailableToUser: boolean | null | undefined;
                } | null | undefined;
              } | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type dialogReverseTrialPopupEditionAwarenessQuery = {
  response: dialogReverseTrialPopupEditionAwarenessQuery$data;
  variables: dialogReverseTrialPopupEditionAwarenessQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hamsProductKey"
  }
],
v1 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "cloudIds.0",
        "variableName": "cloudId"
      }
    ],
    "kind": "ListValue",
    "name": "cloudIds"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "hamsProductKey",
    "variableName": "hamsProductKey"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "offeringName",
    "value": "Free"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "experienceUrl"
},
v5 = {
  "kind": "ScalarField",
  "name": "isAvailableToUser"
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = [
  (v7/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = [
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "dialogReverseTrialPopupEditionAwarenessQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "experienceCapabilities",
                    "plural": false,
                    "selections": [
                      {
                        "args": (v3/*: any*/),
                        "kind": "LinkedField",
                        "name": "changeOfferingV2",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": "changeOfferingV2(offeringName:\"Free\")"
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "accountDetails",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "invoiceGroup",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "experienceCapabilities",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "configurePaymentV2",
                                    "plural": false,
                                    "selections": (v6/*: any*/)
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "dialogReverseTrialPopupEditionAwarenessQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "experienceCapabilities",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "args": (v3/*: any*/),
                        "kind": "LinkedField",
                        "name": "changeOfferingV2",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": "changeOfferingV2(offeringName:\"Free\")"
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "accountDetails",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "invoiceGroup",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "experienceCapabilities",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "name": "configurePaymentV2",
                                    "plural": false,
                                    "selections": (v8/*: any*/)
                                  }
                                ]
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "CcpInvoiceGroup"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v10/*: any*/),
                        "type": "CcpSubscription"
                      }
                    ]
                  },
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "c517efe1f278e6fcd26f8435f985e0afa9794c5e1e81615d9b040697b75959d3",
    "metadata": {},
    "name": "dialogReverseTrialPopupEditionAwarenessQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c94c1d579ff702213c405dc878446d2e";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
