import React from 'react';

import { EntryPoint, EntryPointComponent } from 'react-relay';

import { Box } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { PopupTrigger, type Props } from '@atlassian/entry-point-popup';

import { useEntrypointErrorAnalytics } from '../../../analytics/use-entrypoint-error-analytics';
import { EditionAwarenessButton } from '../../edition-awareness-button';

type AnyEntryPoint = EntryPoint<EntryPointComponent<any, any, any, any>, any>;

export function PopupEntryPoint<TEntryPoint extends AnyEntryPoint>(
	props: Omit<Props<TEntryPoint>, 'trigger'>,
) {
	const onError = useEntrypointErrorAnalytics();

	return (
		<PopupTrigger
			{...props}
			shouldFitContainer={false}
			trigger={({ ref }) => (
				<EditionAwarenessButton ref={ref as unknown as React.Ref<HTMLButtonElement>} />
			)}
			fallback={<PopupFallback />}
			onError={onError}
		/>
	);
}

const PopupFallback = () => (
	<Box padding="space.200">
		<Spinner />
	</Box>
);
