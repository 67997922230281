import { useEffect, useRef } from 'react';

type Props = {
	isOpen: boolean;
	handleClose: (
		event: Event | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | null,
		currentLevel?: any,
	) => void;
};

export function useClickToCloseRef({ isOpen, handleClose }: Props) {
	const ref = useRef<HTMLElement>(null);

	useEffect(() => {
		const el = ref.current;

		if (!el) {
			return;
		}

		const clickListener = () => {
			if (isOpen) {
				handleClose(null);
			}
		};

		el.addEventListener('click', clickListener);

		return () => {
			el.removeEventListener('click', clickListener);
		};
	}, [isOpen, handleClose]);

	return ref;
}
