import React, { Fragment, type ReactNode, useEffect, useRef } from 'react';

import { graphql, useFragment } from 'react-relay';

import { fg } from '@atlaskit/platform-feature-flags';

import { useAnalytics } from '../analytics/use-analytics';
import { useEditionAwareness } from '../services/edition-awareness-context';
import { isEligibleForStandardToPremium } from '../services/is-eligible-for-standard-to-premium';

import type { audienceAndTiming_AtlassianEditionAwareness$key } from './__generated__/audienceAndTiming_AtlassianEditionAwareness.graphql';
import { ActionsAndContent } from './actions-and-content';

/**
 * One off event which tracks when a customer is eligible/ineligible for edition awareness
 */
function useTrackAudienceAndTiming(isEligible: boolean) {
	const { sendAnalyticsEvent } = useAnalytics();
	const hasSent = useRef(false);

	useEffect(() => {
		if (hasSent.current) {
			return;
		}

		sendAnalyticsEvent({
			eventType: 'operational',
			actionSubject: 'audienceAndTiming',
			actionSubjectId: 'editionAwareness',
			action: 'checked',
			attributes: {
				isEligible,
			},
		});

		hasSent.current = true;
	}, [sendAnalyticsEvent, isEligible]);
}

/**
 * Tracks when a user should see the edition awareness experience
 */
function Analytics({ children }: { children: ReactNode }) {
	useTrackAudienceAndTiming(true);

	return <Fragment>{children}</Fragment>;
}

/**
 * Just sends ineligibility info through a `eligibilityCriteria checked` event.
 * This exists so we can optionally render this component
 */
function IneligibleAnalytics() {
	useTrackAudienceAndTiming(false);

	return null;
}

type AudienceAndTimingProps = {
	freeButton: ReactNode;
	trialButton: ReactNode;
	entitlement: audienceAndTiming_AtlassianEditionAwareness$key;
};

/**
 * Based on the audience and timing (e.g. 3 days before the trial ends) we decide to show
 * the free/trial button to the customer. If we want to change the logic of if we show the button or
 * not, it should happen here.
 *
 * Currently `freeButton` and `trialButton` should assume they can render the button and it's only
 * the button content and action when the button is clicked that needs to be fetched/checked
 *
 * `freeButton` and `trialButton` are planned for deprecation when the actions for dialogs is
 * migrated into this package.
 */
export function AudienceAndTiming({
	freeButton,
	trialButton,
	entitlement,
}: AudienceAndTimingProps) {
	const data = useFragment(
		graphql`
			fragment audienceAndTiming_AtlassianEditionAwareness on Query {
				...actionsAndContent_AtlassianEditionAwareness
			}
		`,
		entitlement,
	);

	const context = useEditionAwareness();
	const {
		product,
		edition,
		isInTrial,
		isInPredunning,
		isInReverseTrial,
		canUpgrade,
		isInvoiceable,
		canAddPaymentDetails,
		config,
	} = context;

	const isInTrialOrPredunning = isInTrial || isInPredunning;

	if (config.shouldSkip) {
		return null;
	}

	if (edition === 'free' && canUpgrade) {
		return (
			<Analytics>
				<ActionsAndContent entitlement={data} freeButton={freeButton} />
			</Analytics>
		);
	}

	if (
		edition === 'standard' &&
		isInTrialOrPredunning &&
		isInvoiceable === false &&
		canAddPaymentDetails
	) {
		return (
			<Analytics>
				<ActionsAndContent entitlement={data} trialButton={trialButton} />
			</Analytics>
		);
	}

	// TODO: auto-open eligibility checks
	if (
		edition === 'premium' &&
		product === 'jira' &&
		isInReverseTrial &&
		fg('new_ea_jira_reverse_trial_dropdown')
	) {
		return (
			<Analytics>
				<ActionsAndContent entitlement={data} trialButton={trialButton} />
			</Analytics>
		);
	}

	if (
		edition === 'premium' &&
		product !== 'jira-product-discovery' &&
		isInTrialOrPredunning &&
		isInvoiceable === false &&
		canAddPaymentDetails
	) {
		return <Analytics>{trialButton}</Analytics>;
	}

	if (product === 'confluence' && edition === 'premium' && isInTrialOrPredunning) {
		// Confluence will always show the button for premium while a customer is in a trial or in predunning
		// If the customer can add payment, that is checked as part of rendering the dialog (as the dialog
		// will still show to the customer if they can not add payment details)
		return <Analytics>{trialButton}</Analytics>;
	}

	if (
		(edition === 'free' || isInTrialOrPredunning) &&
		fg('new_ea_audience_and_timing_ineligible_analytics')
	) {
		// We're only firing the ineligible analytics when we could've shown the button, not all instances
		// this is to ensure we don't send the event on every page load
		return <IneligibleAnalytics />;
	}

	if (isEligibleForStandardToPremium(context)) {
		return (
			<Analytics>
				<ActionsAndContent entitlement={data} />
			</Analytics>
		);
	}

	return null;
}
