import React, { useMemo } from 'react';

import { AnalyticsContext } from '@atlaskit/analytics-next';

import { useOnDismissDialog } from '../../../analytics/use-on-dismiss-dialog';
import { useEditionAwareness } from '../../../services/edition-awareness-context';
import { getHamsKey } from '../../../services/product-to-hams-key';
import { PopupEntryPoint } from '../shared/popup-entry-point';

import { DIALOG_CONFIG } from './dialog-config';
import { entryPoint, EntryPointParams } from './entrypoint';

export function ReverseTrialPopupDialog() {
	const { product, cloudId } = useEditionAwareness();

	const entryPointParams: EntryPointParams = useMemo(
		() => ({
			cloudId,
			hamsProductKey: getHamsKey(product),
		}),
		[cloudId, product],
	);

	const onClose = useOnDismissDialog({ ...DIALOG_CONFIG });

	return (
		<AnalyticsContext data={DIALOG_CONFIG}>
			<PopupEntryPoint
				entryPoint={entryPoint}
				entryPointParams={entryPointParams}
				placement={'bottom-end'}
				onClose={onClose}
			/>
		</AnalyticsContext>
	);
}
