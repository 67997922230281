import { createContext, useContext } from 'react';

import type { Edition, Product, SiteType, UserRole } from '../types';

import type { Config } from './use-config';

export interface EditionAwarenessContextType {
	//  ------------------------- Required properties -------------------------
	cloudId: string;
	product: Product;
	edition: Edition;
	config: Config;

	//  ------------------------- Payment properties --------------------------
	/**
	 * If this is null, then we cannot say that the entitlement is invoiceable. Please consider not showing the experience to customers
	 * in this case as they may have payment details already.
	 */
	isInvoiceable?: boolean | null;
	canAddPaymentDetails?: boolean;
	paidEntitlement?: {
		isInvoiceable?: boolean | null;
		canUpgrade: boolean;
	};

	//  --------------------------- Trial properties ---------------------------
	trialDaysLeft?: number;
	trialLengthDays?: number;
	isInTrial?: boolean;
	isInPredunning?: boolean;
	isInReverseTrial?: boolean;

	//  ---------------------- User permissions properties ----------------------
	canUpgrade?: boolean;
	userRoles?: UserRole[];

	//  ---------------------------- Site properties ----------------------------
	/**
	 * This site type is calculated on a trait, when the trait is not loaded is undefined,
	 * if the flag is disabled or no trait is found it will be null.
	 * If the trait is found and the value return an unexpected value it will be 'UNKNOWN'
	 * If the trait is found and the value is 'EDU' it will be 'EDU'
	 * If the trait is found and the value is 'ORG' it will be 'ORG'
	 */
	siteType?: SiteType;
	billingSourceSystem?: 'CCP' | 'HAMS';

	//  ------------------------- Capabilities  -------------------------
	//  ---- Dismissable button ----
	hasDismissedButton?: boolean;
	isSpotlightActive?: boolean;

	//  ---- Spotlight ----
	onDismissButton?: () => void;
	handleSetSpotlight?: (isActive: boolean) => void;

	//  ---- RT Dropdown Premium Features ----
	onOpenOnboardingMenu?: () => void;
}

export type SiteInformationContextType = {
	licensedUsers: number;
	storageUsageInGB?: number;
};

export const EditionAwarenessContext = createContext<EditionAwarenessContextType | null>(null);
export const SiteInformationContext = createContext<SiteInformationContextType | null>(null);

export function useEditionAwareness(): EditionAwarenessContextType {
	const context = useContext(EditionAwarenessContext);

	if (!context) {
		throw new Error('Missing context, please ensure this is used within EditionAwarenessContext');
	}

	return context;
}

export function useSiteInformation(): SiteInformationContextType {
	const context = useContext(SiteInformationContext);

	if (!context) {
		throw new Error('Missing context, please ensure this is used within SiteInformationContext');
	}

	return context;
}
