import FeatureGates from '@atlaskit/feature-gate-js-client';
import { fg } from '@atlaskit/platform-feature-flags';

import { type ExperimentCohort, Product } from '../../../types';

export const useNBEAExperiment = ({
	fireExperimentExposure = false,
	product,
}: {
	fireExperimentExposure: boolean;
	product: Product;
}) => {
	const aaExperimentKey = 'confluence_next_best_edition_recommend_aa';
	let aaCohort: ExperimentCohort = 'not-enrolled';

	if (product === 'confluence' && fg('edition_awareness_confluence_next_best_rec_fg')) {
		aaCohort = FeatureGates.getExperimentValue<ExperimentCohort>(
			aaExperimentKey,
			'cohort',
			'not-enrolled',
			{
				fireExperimentExposure,
			},
		);
		// TODO: Add AB experiment key and cohort when available
	}

	return {
		aaExperimentKey,
		aaCohort,
	};
};
