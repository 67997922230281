//import { AtlBrowserStorageLocal } from '@atlassian/browser-storage-controls';

import { PLATFORM_EDUCATIONAL_DISCOUNT_DISMISSED } from '../ui/dialogs/educational-discount-modal/constants';

import { EditionAwarenessContextType } from './edition-awareness-context';

export const isEligibleForEduDiscount = (context: EditionAwarenessContextType) => {
	return isFreeEduOrOrgSite(context) && eduModalIsNotDismissed();
};

export const isFreeEduOrOrgSite = (context: EditionAwarenessContextType) => {
	const { edition, siteType } = context;
	return edition === 'free' && (siteType === 'EDU' || siteType === 'ORG');
};

export const eduModalIsNotDismissed = () => {
	// AtlBrowserStorageLocal even if is not executed, by just calling this file fails the VR test,
	//  until we have a fix, we can use the native one
	//AtlBrowserStorageLocal.getItem(PLATFORM_EDUCATIONAL_DISCOUNT_DISMISSED) !== 'true'

	try {
		return localStorage.getItem(PLATFORM_EDUCATIONAL_DISCOUNT_DISMISSED) !== 'true';
	} catch (e) {
		return false;
	}
};
